﻿@import "../helpers/_imports.scss";

.tag-selection
{
	display: flex;
	justify-content: flex-start;
	gap: rem(62px);
	margin-top: rem(24px);
	flex-wrap: wrap;

	&__group
	{
		display: flex;
		gap: rem(12px);
		flex-direction: column;

		&__wrap
		{
			display: flex;
			flex-wrap: wrap;
			gap: rem(12px);
		}

		&__label
		{
			width: fit-content;
			margin-bottom: rem(10px);

			input
			{
				display: none;
			}

			input:checked ~ &__pill
			{
				background-color: $color-success;
			}

			&__pill
			{
				border-radius: rem(30px);
				padding: rem(8px 23px);
				font-weight: bold;
				background-color: $color-secondary;
				color: $color-light1;
				font-size: rem(16px);
				user-select: none;
				transition: all 0.2s;
				cursor: pointer;
				border: 1px solid #002a51;

				&--view
				{
					background-color: $color-success;
					cursor: default;

					&:hover
					{
						background-color: $color-success !important;
					}
				}
			}
		}
	}
}