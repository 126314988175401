﻿@import "../helpers/_imports.scss";

.splash-screen
{
	background: $color-primary;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;

	&__logo
	{
		@include brand-logo($ratio: 2);
	}

	&__loading-icon
	{
		margin-top: rem(42px);

		&::after
		{
			@include fa-font-icon($fa-var-spinner, solid);
			animation: fa-spin 2s infinite linear;
			font-size: rem(48px);
			color: $color-light1;
		}
	}
}