﻿@import "../helpers/_imports.scss";

/*
	The Umbrella.AspNetCore.Blazor package contains core styling for the components.
	CSS Variables can be used to override some of the defaults. We need to use CSS Variables instead of SASS variables because the source has already been compiled
	from SASS to CSS when building and publishing the package to NuGet.
	For more information on the available CSS Variables, refer to the source stylesheet for each component (in the Umbrella.AspNetCore.Blazor project)
	at: /Content/styles/components
*/
:root
{
	// Breadcrumb
	--u-breadcrumb-color: #{$color-light1};
	--u-breadcrumb-color-hover: #{$color-light1};
	--u-breadcrumb-color-active: #{$color-light1};
	--u-breadcrumb-gap: #{rem(8px)};
	// Checkbox
	--u-checkbox-color-primary: #{$color-primary};
	--u-checkbox-color-secondary: #{$color-light1};
	--u-checkbox-width: #{rem(22px)};
	--u-checkbox-height: #{rem(22px)};
	--u-checkbox-spacing: #{rem(8px)};
	--u-checkbox-border-width: #{rem(1px)};
	--u-checkbox-border-radius: #{rem(5px)};
	// Grid
	--u-grid-heading-font-size: #{rem(16px)};
	--u-grid-heading-icon-font-size: #{rem(20px)};
	--u-grid-heading-icon-font-color: #{$color-secondary};
	--u-grid-heading-gap: #{rem(5px)};
	--u-grid-heading-margin: #{rem(0 0 7px 0)};
	--u-grid-spacing: #{rem(16px)};
	// Radio
	--u-radio-color-primary: #{$color-primary};
	--u-radio-color-secondary: #{$color-light1};
	--u-radio-width: #{rem(22px)};
	--u-radio-height: #{rem(22px)};
	--u-radio-spacing: #{rem(8px)};
	--u-radio-border-width: #{rem(1px)};
	--u-radio-border-radius: #{rem(5px)};
	// State View
	--u-state-view-color-primary: #{$color-primary-alt};
	--u-state-view-font-weight: #{map-get($font-weights, bold)};
}

.u-grid {
	&__actions {
		display: flex;
		justify-content: flex-end;
		gap: 4px;

		.hidden {
			visibility: hidden;
		}
	}
}