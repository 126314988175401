﻿@import "./helpers/_imports.scss";

*:focus-visible
{
	outline: none;
}

html
{
	position: relative;
	height: 100%;
	font-size: 14px;

	@include media-breakpoint-up(md)
	{
		font-size: $rem-baseline;
	}
}

body
{
	overflow-x: hidden;
	background: $color-background-alt;
}

app
{
	position: relative;
	display: flex;
	flex-direction: column;
}

h1
{
	@include font-weight(semi-bold);
	font-size: $font-size-h1;
	color: $color-dark1;
}

h2
{
	@include font-weight(semi-bold);
	font-size: $font-size-h2;
}

h3
{
	@include font-weight(semi-bold);
	font-size: $font-size-h3;
}

h4
{
	@include font-weight(semi-bold);
	font-size: $font-size-h4;
}

.brand-logo
{
	display: inline-block;
	@include brand-logo();
}