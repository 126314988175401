﻿@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../../../node_modules/bootstrap/scss/_variables.scss";
@import "../../../node_modules/bootstrap/scss/_mixins.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/_functions.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/_variables.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/_mixins.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/_animated.scss";
@import "_variables";

@mixin background-gradient($start-color, $end-color)
{
	background: $start-color; /* Old browsers */
	background: -moz-linear-gradient(top, $start-color 0, $end-color 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0,$end-color), color-stop(100%,$end-color)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, $start-color 0,$end-color 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, $start-color 0,$end-color 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, $start-color 0,$end-color 100%); /* IE10+ */
	background: linear-gradient(to bottom, $start-color 0,$end-color 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$start-color, endColorstr=$end-color,GradientType=0 ); /* IE6-9 */
}

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x, $file-3x: null)
{
	background-image: url("#{$file-1x}");
	width: $width-1x;
	height: $height-1x;

	@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and ( min--moz-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 2/1), only screen and ( min-device-pixel-ratio: 2), only screen and ( min-resolution: 192dpi), only screen and ( min-resolution: 2dppx)
	{
		background-image: url("#{$file-2x}");
		background-size: $width-1x $height-1x;
	}

	@if $file-3x != null
	{
		@media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and ( min--moz-device-pixel-ratio: 3), only screen and ( -o-min-device-pixel-ratio: 3/1), only screen and ( min-device-pixel-ratio: 3), only screen and ( min-resolution: 384dpi), only screen and ( min-resolution: 3dppx)
		{
			background-image: url("#{$file-3x}");
			background-size: $width-1x $height-1x;
		}
	}
}

@mixin hover($color, $background: none, $text-decoration: none)
{
	&:hover
	{
		color: $color;
		text-decoration: $text-decoration;
		background: $background;
	}
}

@mixin clearfix()
{
	&:before,
	&:after
	{
		display: table;
		content: " ";
	}

	&:after
	{
		clear: both;
	}
}

@mixin user-select($value: none)
{
	-moz-user-select: $value;
	-ms-user-select: $value;
	-webkit-user-select: $value;
	user-select: $value;
}

@mixin loading($font-size, $color: #fff)
{
	position: absolute;
	display: none;
	align-items: center;
	justify-content: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	&::before
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		content: "";
		background-color: #000;
		opacity: 0.5;
		width: 100%;
		height: 100%;
		z-index: 2000;
	}

	.loading-screen-icon
	{
		opacity: 1;
		position: relative;
		width: $font-size;
		height: $font-size;
		z-index: 3000;

		&::after
		{
			@include fa-font-icon($fa-var-spinner, solid);
			animation: fa-spin 2s infinite linear;
			font-size: rem($font-size);
			color: $color;
		}
	}
}

@mixin font-weight($weight: regular)
{
	font-weight: map-get($font-weights, $weight);
}

@mixin fa-font-icon($icon, $style)
{
	@include fa-icon();
	font-family: $fa-style-family;
	content: fa-content($icon);
	font-weight: map-get($fa-styles, $style);
}

@mixin brand-logo($color: $color-primary, $ratio: 1)
{
	$width: 94;
	$aspect-ratio: calc(34 / $width);

	@if $ratio != max
	{
		$width: $width * $ratio;
		width: px-rem($width);
		padding-bottom: px-rem($width * $aspect-ratio);
	}
	@else
	{
		width: 100%;
		padding-bottom: 100% * $aspect-ratio;
	}

	height: 0;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(../../images/rio-logo.svg);
}

@function px-rem($value)
{
	@return #{calc($value / $rem-baseline-raw)}rem;
}

@mixin scrollbar()
{
	&::-webkit-scrollbar
	{
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-button
	{
		width: 0px;
		height: 0px;
	}

	&::-webkit-scrollbar-thumb
	{
		background: #999;
		border: 0px none #ffffff;
		border-radius: 50px;
	}

	&::-webkit-scrollbar-thumb:hover
	{
		background: #999;
	}

	&::-webkit-scrollbar-thumb:active
	{
		background: #666;
	}

	&::-webkit-scrollbar-track
	{
		background: #e1e1e1;
		border: 0px none #ffffff;
		border-radius: 50px;
	}

	&::-webkit-scrollbar-track:hover
	{
		background: #e1e1e1;
	}

	&::-webkit-scrollbar-track:active
	{
		background: #e1e1e1;
	}

	&::-webkit-scrollbar-corner
	{
		background: transparent;
	}
}

@mixin focused-area($includeShadow: true, $padding: 24px)
{
	border-radius: $border-radius-lg;
	background: #f6f6f6;
	padding: rem($padding);
	border: 1px solid #c8d0d7;

	@if $includeShadow
	{
		box-shadow: 0 11px 9px -4px #aeaeae45;
	}
}
