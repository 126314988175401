﻿@import "../../../node_modules/bootstrap/scss/_functions.scss";
@import "../helpers/_variables.scss";

// Features
$enable-rounded: true;

// Border Radius
$border-radius: rem(7px);
$border-radius-lg: rem(8px);
$border-radius-sm: rem(5px);

// Fonts
$font-family-base: $font-family-body;
$font-weight-light: map-get($font-weights, light);
$font-weight-normal: map-get($font-weights, regular);
$font-weight-bold: map-get($font-weights, bold);
$font-weight-bolder: map-get($font-weights, heavy);
$headings-font-family: $font-family-heading;
$headings-font-weight: map-get($font-weights, bold);

$font-size-base: rem($rem-baseline);
$font-size-lg: $font-size-base * 1;
$font-size-sm: $font-size-base * .85;

// Buttons
$btn-font-weight: map-get($font-weights, bold);

// Sizes
$line-height-lg: 2.5;
$line-height-sm: 1.5;

$card-height: 100%;

$form-group-margin-bottom: 1.3rem;

// Colors
$body-color: $color-dark2;
$theme-colors: ( primary: $color-primary, secondary: $color-secondary, danger: $color-danger, info: $color-info, warning: $color-warning, success: $color-success);

// Modals
$modal-content-border-radius: 15px;
$modal-content-box-shadow-xs: rem(0 4px 20px 5px) rgba(0,0,0,0.2);

@import "../../../node_modules/bootstrap/scss/_variables.scss";

:root
{
	--bs-link-color: #{$color-primary} !important;
	--bs-link-color-rgb: #{$color-primary} !important;
	--bs-link-hover-color: #{$color-primary} !important;
	--bs-link-hover-color-rgb: #{$color-primary} !important;
}

.pagination
{
	--bs-pagination-active-bg: #{$color-primary} !important;
	--bs-pagination-active-border-color: #{$color-primary} !important;
}

.dropdown-menu
{
	--bs-dropdown-link-active-bg: #{$color-primary} !important;
}

.breadcrumb
{
	--bs-breadcrumb-margin-bottom: 0 !important;
	--bs-breadcrumb-divider: '>';
	--bs-breadcrumb-item-padding-x: 0 !important;
	--bs-breadcrumb-divider-color: #{$color-light1} !important;
}