﻿// Colors
$color-dark1: #000;
$color-dark2:#333;
$color-dark3:#434343;

$color-light1: #fff;
$color-light2: #e5e5e5;
$color-light3: #c3c3c3;

$color-primary: #002a51;
$color-primary-alt: #00386d;
$color-secondary: #9e9e9e;
$color-success: #84bd00;
$color-danger: #d60057;
$color-info: #00b5e2;
$color-warning: #ea7600;
$color-blue: #00b5e2;
$color-purple: #8c189b;
$color-purple-alt: #781c93;

$color-background: #fff;
$color-background-alt: #fff;

// Sizes - NB: The $rem-baseline is used by the rem plugin to calculate values
$rem-baseline-raw: 14;
$rem-baseline: 14px;

$font-size-h1: 1.5rem;
$font-size-h2: 1.4rem;
$font-size-h3: 1.3rem;
$font-size-h4: 1.3rem;

// Font
$font-family-heading: "RT Vickerman", sans-serif;
$font-family-body: "RT Vickerman", sans-serif;
$font-weights: ("light": 300, "regular": 400, "bold": 700);
$fa-styles: ("regular": 400, "solid": 900);