﻿@font-face
{
	font-family: "RT Vickerman";
	font-style: normal;
	font-weight: 400;
	src: url(../../../wwwroot/fonts/RTVickerman.otf)
}

@font-face
{
	font-family: "RT Vickerman";
	font-style: italic;
	font-weight: 400;
	src: url(../../../wwwroot/fonts/RTVickerman-Italic.otf)
}

@font-face
{
	font-family: "RT Vickerman";
	font-style: italic;
	font-weight: 300;
	src: url(../../../wwwroot/fonts/RTVickerman-Light.otf)
}

@font-face
{
	font-family: "RT Vickerman";
	font-style: italic;
	font-weight: 300;
	src: url(../../../wwwroot/fonts/RTVickerman-LightItalic.otf)
}

@font-face
{
	font-family: "RT Vickerman";
	font-style: normal;
	font-weight: 700;
	src: url(../../../wwwroot/fonts/RTVickerman-Bold.otf)
}

@font-face
{
	font-family: "RT Vickerman";
	font-style: italic;
	font-weight: 700;
	src: url(../../../wwwroot/fonts/RTVickerman-BoldItalic.otf)
}