﻿@import "../helpers/_imports.scss";

// Blazor error message
.validation-message
{
	color: $color-danger;
	font-size: rem(12px);
	margin-top: rem(3px);
}

.focused-area
{
	@include focused-area();
}

.form-control
{
	&.invalid, // Blazor error
	&.input-validation-error // Server error
	{
		border-color: $color-danger !important;
	}
}

// Server error message
.field-validation-error
{
	display: block;
	font-size: rem(12px);
}

.valid.modified:not([type=checkbox])
{
}

.custom-radio-lg
{
	margin-bottom: rem(5px);

	.custom-control-label
	{
		padding-left: rem(8px);

		&::before,
		&::after
		{
			width: rem(20px);
			height: rem(20px);
			top: rem(1px);
		}
	}
}

.custom-control
{
	.custom-control-input
	{
		&:checked
		{
			& + .custom-control-label
			{
				&::before,
				&::after
				{
				}

				&::after
				{
				}
			}
		}
	}

	.custom-control-label
	{
		&::before,
		&::after
		{
		}
	}
}

textarea.form-control
{
	min-height: rem(150px);
}

.form-group
{
	margin-bottom: rem(16px);

	.form-text
	{
		margin-bottom: rem(4px);
	}

	&--buttons
	{
		display: flex;
		margin-top: rem(8px);
		gap: rem(10px);

		button,
		a
		{
			flex: 1 0;
		}
	}

	&--radio-buttons
	{
		display: flex;
		flex-direction: column;
		gap: rem(14px);
	}
}

.btn
{
	white-space: nowrap;

	&.btn-light
	{
		color: $color-primary;
		background: $color-light1;
		border: rem(1px) solid $color-primary;
		@include font-weight(semi-bold);

		&:hover
		{
			background: none;
			color: $color-light1;
			border: rem(1px) solid $color-light1;
		}
	}

	&.btn-secondary
	{
		color: $color-light1;

		&:hover
		{
			color: $color-light1;
		}
	}

	&.btn-success
	{
		--bs-btn-color: #{$color-light1};
		--bs-btn-hover-color: #{$color-light1};
	}

	&.btn-blue
	{
		color: $color-light1;
		background: $color-blue;
		border: rem(1px) solid $color-blue;
		@include font-weight(semi-bold);

		&:hover
		{
			background: darken($color-blue, 7.5%);
			color: $color-light1;
			border-color: darken($color-blue, 10%);
		}
	}

	&-lg
	{
		--bs-btn-padding-y: 1rem;
	}

	&-purple
	{
		@include button-variant($color-purple, $color-purple);
	}
}

.input-group-text
{
	&.fas
	{
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

input[type="number"]
{
	-webkit-appearance: textfield !important;
	-moz-appearance: textfield !important;
	appearance: textfield !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
	-webkit-appearance: none;
}

.form-options
{
	.u-radio,
	.u-checkbox
	{
		margin: rem(12px 0 0);
	}
}
